import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
var Pergunta = /** @class */ (function (_super) {
    __extends(Pergunta, _super);
    function Pergunta() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.chip = '';
        _this.chips = new Set();
        return _this;
    }
    Pergunta.prototype.changeValue = function (novo, velho) {
        this.chips = new Set(novo);
    };
    Pergunta.prototype.mounted = function () {
        this.chips = new Set(this.value);
    };
    Pergunta.prototype.addItem = function () {
        if (this.chip.trim().length > 0)
            this.chips.add(this.chip.trim());
        this.chip = '';
        this.$emit('input', Array.from(this.chips));
    };
    Pergunta.prototype.btDelete = function (oque) {
        this.chip = oque;
        this.chips.delete(oque);
        this.$emit('input', Array.from(this.chips));
    };
    __decorate([
        Prop({ default: '' })
    ], Pergunta.prototype, "label", void 0);
    __decorate([
        Prop({ default: 30 })
    ], Pergunta.prototype, "maxlength", void 0);
    __decorate([
        Prop({ default: 'default' })
    ], Pergunta.prototype, "color", void 0);
    __decorate([
        Prop({ default: 'default' })
    ], Pergunta.prototype, "textColor", void 0);
    __decorate([
        Prop({ default: false })
    ], Pergunta.prototype, "disabled", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], Pergunta.prototype, "value", void 0);
    __decorate([
        Watch('value')
    ], Pergunta.prototype, "changeValue", null);
    Pergunta = __decorate([
        Component
    ], Pergunta);
    return Pergunta;
}(Vue));
export default Pergunta;
