import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ImageUploader from 'vue-image-upload-resize';
import { VueEditor } from "vue2-editor";
import Alerta from '../../components/alert.vue';
// @ts-ignore
import { mask } from 'vue-the-mask';
import { TipoVagaRole } from '@/interfaces/backend/UsuarioInterfaces';
var ListarCandidatosCls = /** @class */ (function (_super) {
    __extends(ListarCandidatosCls, _super);
    function ListarCandidatosCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Candidatos',
            mensagem: '',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.rsVaga = _this.resetModel();
        _this.search = '';
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            {
                text: 'Nome',
                align: 'left',
                sortable: true,
                value: 'nome'
            },
            { text: 'Ações', value: 'actions', sortable: false },
        ];
        _this.registros = [];
        _this.rsPDF = [];
        _this.refreshDownload = 0;
        return _this;
    }
    ListarCandidatosCls.prototype.mounted = function () {
        this.rsVaga = this.$route.params.rsVaga ? this.$route.params.rsVaga : this.resetModel();
        this.getDetalhe();
    };
    ListarCandidatosCls.prototype.resetModel = function () {
        return {
            idVaga: 0,
            idUsuario: 0,
            descritivo: '',
            ativa: false,
            dataLimite: '',
            horario: '',
            remuneracao: '',
            empresa: {
                idEmpresa: 0,
                idUsuario: 0,
                nome: '',
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                uf: '',
                fone: '',
                perfil: '',
                whatsapp: '',
                email: '',
                twitter: '',
                facebook: '',
                instagram: '',
                linkedin: '',
                logo: ''
            },
            tipo: TipoVagaRole.CLT,
            titulo: '',
            idEmpresa: 0,
            beneficios: [],
            questionario: []
        };
    };
    ListarCandidatosCls.prototype.getDetalhe = function () {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Candidatos';
        var query = "\n      getCandidatos(idVaga: " + this.rsVaga.idVaga + ") {\n        idCurriculum\n        idUsuario\n        nome\n      }    \n    ";
        this.clsApollo.query(this, query, 'getCandidatos', this.mensagemAlerta).then(function (rs) {
            console.log('rs', rs);
            if (rs && rs.length > 0) {
                _this.registros = rs;
                _this.rsPDF = new Array(rs.length);
            }
            else {
                _this.registros = [];
                _this.rsPDF = [];
            }
            _this.mensagemAlerta.exibir = false;
        });
    };
    ListarCandidatosCls.prototype.btDownload = function (idUsuario, index) {
        var _this = this;
        var query = "\n      downloadCurriculumRH(idUsuario: " + idUsuario + ") {\n        ok\n        mensagem\n        arquivo\n      }\n    ";
        this.mensagemAlerta.exibir = true;
        var clsApolloClient = new ClsApolloClient();
        this.mensagemAlerta.titulo = "Curriculum";
        this.mensagemAlerta.mensagem = "Download Curriculum...";
        clsApolloClient.query(this, query, 'downloadCurriculumRH', this.mensagemAlerta).then(function (rs) {
            if (rs) {
                _this.rsPDF[index] = 'data:application/pdf;base64,'.concat(rs.arquivo);
                _this.refreshDownload++;
            }
            _this.mensagemAlerta.exibir = false;
        });
    };
    ListarCandidatosCls = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                VueEditor: VueEditor,
                ImageUploader: ImageUploader
            },
            directives: {
                mask: mask
            }
        })
    ], ListarCandidatosCls);
    return ListarCandidatosCls;
}(Vue));
export default ListarCandidatosCls;
