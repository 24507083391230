import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import { StatusStoreModule } from '../../store/modules/StatusStore';
import { LoginStoreModule } from '../../store/modules/LoginStore';
var Notificacao = /** @class */ (function (_super) {
    __extends(Notificacao, _super);
    function Notificacao() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Notificacao.prototype, "logado", {
        get: function () {
            return LoginStoreModule.logado;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Notificacao.prototype, "status", {
        get: function () {
            return StatusStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    Notificacao.prototype.irPara = function (onde) {
        this.$router.push(onde);
    };
    Object.defineProperty(Notificacao.prototype, "notificacoes", {
        get: function () {
            return [
                {
                    titulo: 'Complete seu Cadastro',
                    descricao: 'Dados Pessoais são importantes na análise do curriculum.',
                    link: '/StepByStep',
                    icone: 'mdi-account-details',
                    exibir: !this.status.DadosPessoais
                },
                {
                    titulo: 'Contatos Digitais',
                    descricao: 'Mostra seu nível de conexão e relacionamentos. É uma fonte de informação sobre você.',
                    icone: 'mdi-account-search-outline',
                    link: '/StepByStep',
                    exibir: !this.status.ContatosDigitais
                },
                {
                    titulo: 'Perfil',
                    descricao: 'O Perfil é um resumo de como você é e o que espera.',
                    link: '/StepByStep',
                    icone: 'mdi-comment-account-outline',
                    exibir: !this.status.Perfil
                },
                {
                    titulo: 'Formação',
                    descricao: 'Fale sobre sua formação acadêmica.',
                    link: '/StepByStep',
                    icone: 'mdi-bank',
                    exibir: !this.status.Formacao
                },
                {
                    titulo: 'Cursos',
                    descricao: 'Os cursos são importantes pois trabalham habilidades específicas indispensáveis.',
                    link: '/StepByStep',
                    icone: 'mdi-text-account',
                    exibir: !this.status.Cursos
                },
                {
                    titulo: 'Experiências',
                    descricao: 'As Experiências retratam sua vivência em determinadas áreas da empresa.',
                    link: '/StepByStep',
                    icone: 'mdi-card-account-details-outline',
                    exibir: !this.status.Experiencias
                },
                {
                    titulo: 'Habilidades',
                    descricao: 'Classifique todas as suas habilidades relevantes.',
                    link: '/StepByStep',
                    icone: 'mdi-account-star-outline',
                    exibir: !this.status.Habilidades
                },
                {
                    titulo: 'Selfie',
                    descricao: 'Uma boa apresentação é importante!',
                    link: '/Selfie',
                    icone: 'mdi-account-circle',
                    exibir: !this.status.Selfie
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    Notificacao = __decorate([
        Component
    ], Notificacao);
    return Notificacao;
}(Vue));
export default Notificacao;
