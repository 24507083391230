import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '@/utils/ClsApolloClient';
var Pergunta = /** @class */ (function (_super) {
    __extends(Pergunta, _super);
    function Pergunta() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApollo = new ClsApolloClient();
        _this.items = [];
        _this.search = "";
        _this.loading = false;
        return _this;
    }
    Pergunta.prototype.inputEvent = function (novoValor) {
        this.$emit('input', novoValor);
    };
    Pergunta.prototype.mounted = function () {
        this.getItems();
    };
    Pergunta.prototype.getItems = function () {
        var _this = this;
        this.loading = true;
        this.mensagemAlerta.mensagem = 'Pequisando '.concat(this.titulo).trim().concat('....');
        this.mensagemAlerta.exibir = true;
        this.mensagemAlerta.tipo = TipoAlertaMensagemType.aguarde;
        var query = "\n      " + this.query + "(search: \"" + this.search + "\") {\n        " + this.campoCodigo + "\n          " + this.campoDescricao + "\n        }\n    ";
        this.clsApollo.query(this, query, this.query, this.mensagemAlerta).then(function (rs) {
            _this.items = rs;
            _this.loading = false;
            _this.mensagemAlerta.exibir = false;
        });
    };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], Pergunta.prototype, "mensagemAlerta", void 0);
    __decorate([
        Prop({ default: 'id' })
    ], Pergunta.prototype, "campoCodigo", void 0);
    __decorate([
        Prop({ default: 'descricao' })
    ], Pergunta.prototype, "campoDescricao", void 0);
    __decorate([
        Prop()
    ], Pergunta.prototype, "value", void 0);
    __decorate([
        Prop({ default: undefined })
    ], Pergunta.prototype, "rules", void 0);
    __decorate([
        Prop({ default: '' })
    ], Pergunta.prototype, "titulo", void 0);
    __decorate([
        Prop({ default: '' })
    ], Pergunta.prototype, "classePadrao", void 0);
    __decorate([
        Prop({ default: '' })
    ], Pergunta.prototype, "query", void 0);
    __decorate([
        Prop({ default: false })
    ], Pergunta.prototype, "disabled", void 0);
    Pergunta = __decorate([
        Component
    ], Pergunta);
    return Pergunta;
}(Vue));
export default Pergunta;
