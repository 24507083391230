import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Alerta from '../../components/alert.vue';
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '../../utils/ClsApolloClient';
import ClsUtils from 'zlib-utils/ClsUtils';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
// @ts-ignore
import { mask } from 'vue-the-mask';
var NovoUsuarioCls = /** @class */ (function (_super) {
    __extends(NovoUsuarioCls, _super);
    function NovoUsuarioCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Novo Usuário',
            mensagem: 'Criando...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.exibirSenha = false;
        /*
        private statusForm: StatusForm = StatusForm.inicial
      
      */
        _this.registro = EMDESENVOLVIMENTO ? {
            login: 'ZanattaFleek',
            nome: 'Marcelo João Zanatta',
            senha: 'Teste123',
            email: 'zanatta@peopledivinopolis.com.br',
            whatsApp: '37988118785'
        } :
            {
                login: '',
                nome: '',
                senha: '',
                email: '',
                whatsApp: ''
            };
        return _this;
    }
    Object.defineProperty(NovoUsuarioCls.prototype, "validadoresFrontEnd", {
        get: function () {
            return validadoresFrontEnd;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NovoUsuarioCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    NovoUsuarioCls.prototype.cadastrar = function () {
        var _this = this;
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.mensagemAlerta.mensagem = 'Incluindo Novo Usuário...';
            this.mensagemAlerta.exibir = true;
            this.status = StatusFormType.processando;
            this.confirmarCadastro().then(function (rs) {
                _this.mensagemAlerta.mensagem = rs.mensagem;
                if (rs.ok) {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.$router.push('/');
                }
                else {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                console.log('ERRO....', err);
            });
        }
    };
    NovoUsuarioCls.prototype.confirmarCadastro = function () {
        var clsUtils = new ClsUtils();
        var cadastrar = "\n      novoUsuario (usuario: " + clsUtils.ConverterEmGql(this.registro) + ") {\n          ok\n          mensagem\n        }    \n    ";
        var clsApolloClient = new ClsApolloClient();
        return clsApolloClient.mutation(this, cadastrar, 'novoUsuario', this.mensagemAlerta);
    };
    NovoUsuarioCls = __decorate([
        Component({
            components: {
                Alerta: Alerta
            },
            directives: {
                mask: mask
            }
        })
    ], NovoUsuarioCls);
    return NovoUsuarioCls;
}(Vue));
export default NovoUsuarioCls;
