import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Alerta from '../../components/alert.vue';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { getTIPOVAGADESCRICAO } from '@/utils/dataTypes';
import { ISO_TO_USUARIO_DATA } from '@/utils/FormatarDados';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { TipoVagaRole } from '@/interfaces/backend/UsuarioInterfaces';
import questionarioResposta from '@/components/questionarioResposta.vue';
import _ from 'lodash';
import ClsUtils from 'zlib-utils/ClsUtils';
var VagasCandidatar = /** @class */ (function (_super) {
    __extends(VagasCandidatar, _super);
    function VagasCandidatar() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = true;
        _this.questionario = [];
        _this.resposta = [];
        _this.getTIPOVAGADESCRICAO = getTIPOVAGADESCRICAO;
        _this.ISO_TO_USUARIO_DATA = ISO_TO_USUARIO_DATA;
        _this.keyRefresh = 0;
        _this.clsApollo = new ClsApolloClient();
        _this.rsVaga = _this.resetModel();
        _this.mensagemAlerta = {
            titulo: 'Candidatar A Vaga',
            mensagem: 'Obtendo Dados. Aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        return _this;
    }
    Object.defineProperty(VagasCandidatar.prototype, "LoginStoreModule", {
        get: function () { return LoginStoreModule; },
        enumerable: false,
        configurable: true
    });
    VagasCandidatar.prototype.resetModel = function () {
        return {
            idVaga: 0,
            idUsuario: 0,
            descritivo: '',
            ativa: false,
            dataLimite: '',
            horario: '',
            remuneracao: '',
            tipo: TipoVagaRole.CLT,
            titulo: '',
            idEmpresa: 0,
            beneficios: [],
            questionario: [],
            empresa: {
                idEmpresa: 0,
                idUsuario: 0,
                nome: '',
                cep: '',
                endereco: '',
                bairro: '',
                cidade: '',
                uf: '',
                fone: '',
                perfil: '',
                whatsapp: '',
                email: '',
                twitter: '',
                facebook: '',
                instagram: '',
                linkedin: '',
                logo: ''
            }
        };
    };
    VagasCandidatar.prototype.mounted = function () {
        if (this.$route.params.idVaga) {
            this.getDados(this.$route.params.idVaga);
        }
    };
    VagasCandidatar.prototype.getDados = function (idVaga) {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Vaga';
        var query = "\n      getVaga (idVaga: " + idVaga + ") {\n        idVaga\n        idUsuario\n        descritivo\n        ativa\n        dataLimite\n        horario\n        remuneracao\n        tipo\n        titulo\n        empresa {\n          logo\n        }\n        beneficios {\n          descricao\n        }\n        questionario {\n          idQuestionario\n          pergunta\n          configuracao\n        }\n      }\n    ";
        this.clsApollo.query(this, query, 'getVaga', this.mensagemAlerta).then(function (rs) {
            _this.rsVaga = rs;
            _this.rsVaga.beneficios = _.map(rs.beneficios, 'descricao');
            _this.rsVaga.dataLimite = ISO_TO_USUARIO_DATA(_this.rsVaga.dataLimite);
            _this.questionario = _.map(rs.questionario, function (oque) {
                return { idQuestionario: oque.idQuestionario, pergunta: oque.pergunta, configuracao: JSON.parse(oque.configuracao) };
            });
            _this.keyRefresh++;
            _this.mensagemAlerta.exibir = false;
        });
    };
    VagasCandidatar.prototype.btConfirmar = function () {
        var _this = this;
        var mutation = "\n      candidatar(idVaga: " + this.rsVaga.idVaga + ", respostas: " + new ClsUtils().ConverterEmGql(this.resposta) + ") {\n        ok\n        mensagem\n      }    \n    ";
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.clsApollo.mutation(this, mutation, 'candidatar', this.mensagemAlerta, 'Registrando... ').then(function (rs) {
                if (rs.ok) {
                    _this.mensagemAlerta.titulo = 'Vagas';
                    _this.mensagemAlerta.mensagem = 'Registro de Interesse Realizado!';
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.mensagemAlerta.exibir = true;
                    _this.$router.push('/Vagas');
                }
            });
        }
    };
    VagasCandidatar.prototype.btCancelar = function () {
        this.$router.push('/Vagas');
    };
    VagasCandidatar = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                questionarioResposta: questionarioResposta
            }
        })
    ], VagasCandidatar);
    return VagasCandidatar;
}(Vue));
export default VagasCandidatar;
