import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import Alerta from '../../components/alert.vue';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsUtils from 'zlib-utils/ClsUtils';
import ImageUploader from 'vue-image-upload-resize';
import { EMDESENVOLVIMENTO, TAMANHO_MAXIMO_PAYLOAD } from '@/config/backend/emDesenvolvimento';
import { StatusStoreModule } from '@/store/modules/StatusStore';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.EMDESENVOLVIMENTO = EMDESENVOLVIMENTO;
        _this.mensagemAlerta = {
            titulo: 'Selfie',
            mensagem: 'Obtendo Selfie. Aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.registro = {
            selfie: '',
        };
        /* CAMERA */
        _this.fotoSelfie = "";
        return _this;
    }
    Object.defineProperty(App.prototype, "StatusFormType", {
        get: function () { return StatusFormType; },
        enumerable: false,
        configurable: true
    });
    App.prototype.mounted = function () {
        this.getDados();
    };
    App.prototype.getDados = function () {
        var _this = this;
        var query = "\n      getSelfie {\n        selfie\n      }    \n    ";
        this.mensagemAlerta.exibir = true;
        var clsApolloClient = new ClsApolloClient();
        clsApolloClient.query(this, query, 'getSelfie', this.mensagemAlerta).then(function (rs) {
            if (rs) {
                _this.fotoSelfie = rs.selfie;
                _this.registro.selfie = rs.selfie;
            }
            _this.mensagemAlerta.exibir = false;
        });
    };
    App.prototype.gravar = function () {
        var _this = this;
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.mensagemAlerta.mensagem = 'Gravando Selfie...';
            this.mensagemAlerta.exibir = true;
            this.status = StatusFormType.processando;
            StatusStoreModule.refreshStatus(this);
            this.confirmarSelfie().then(function (rs) {
                _this.mensagemAlerta.mensagem = rs.mensagem;
                if (rs.ok) {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.$router.push('/');
                }
                else {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                console.log('ERRO....', err);
            });
        }
    };
    App.prototype.confirmarSelfie = function () {
        var clsUtils = new ClsUtils();
        var mutation = "\n      setSelfie(dados: " + clsUtils.ConverterEmGql(this.registro) + ") {\n        ok\n        mensagem\n      }\n    ";
        var clsApolloClient = new ClsApolloClient();
        return clsApolloClient.mutation(this, mutation, 'setSelfie', this.mensagemAlerta);
    };
    App.prototype.onInputFoto = function (imagem) {
        if (imagem.length > TAMANHO_MAXIMO_PAYLOAD) {
            this.mensagemAlerta.mensagem = "Imagem Muito Grande. Diminua a resolução da Câmera!";
            this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
            this.mensagemAlerta.exibir = true;
        }
        else {
            this.fotoSelfie = imagem;
            this.registro.selfie = imagem;
        }
    };
    App.prototype.onUploadFoto = function () {
        this.mensagemAlerta.mensagem = "Carregando....";
        this.mensagemAlerta.tipo = TipoAlertaMensagemType.aguarde;
        this.mensagemAlerta.exibir = true;
    };
    App.prototype.onCompleteFoto = function () {
        this.mensagemAlerta.exibir = false;
    };
    App = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                ImageUploader: ImageUploader
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
