import { __assign, __decorate, __extends, __spreadArrays } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import questionarioPergunta from '@/components/questionarioPergunta.vue';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { PerguntaType } from '@/interfaces/backend/RHInterfaces';
var VagaRHQuestionarioCls = /** @class */ (function (_super) {
    __extends(VagaRHQuestionarioCls, _super);
    function VagaRHQuestionarioCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formValid = false;
        _this.dialog = false;
        _this.keyRefresh = 0;
        _this.status = StatusFormType.inicial;
        _this.editedItem = _this.resetModel();
        _this.indexItem = 0;
        _this.cabecalhos = [
            {
                text: 'Pergunta',
                align: 'left',
                sortable: true,
                value: 'pergunta'
            },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        return _this;
    }
    VagaRHQuestionarioCls.prototype.changeValue = function (novo) {
        this.registros = __spreadArrays(novo);
    };
    VagaRHQuestionarioCls.prototype.mounted = function () {
        this.registros = __spreadArrays(this.value);
    };
    Object.defineProperty(VagaRHQuestionarioCls.prototype, "classePadrao", {
        get: function () {
            return 'py-1 my-0';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VagaRHQuestionarioCls.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.incluindo ? 'Incluir' : this.status == StatusFormType.editando ? "Editar" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VagaRHQuestionarioCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    VagaRHQuestionarioCls.prototype.btCancelar = function () {
        this.dialog = false;
        this.status = StatusFormType.inicial;
    };
    VagaRHQuestionarioCls.prototype.resetModel = function () {
        return {
            configuracao: {
                opcoes: [],
                tamanho: 0,
                tipo: 0
            },
            pergunta: ''
        };
    };
    VagaRHQuestionarioCls.prototype.btIncluir = function () {
        this.status = StatusFormType.incluindo;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = { "pergunta": "Vaga Teste Aplicativo 31/01/2021", "configuracao": { "tipo": 3, "tamanho": 0, "opcoes": ["Escolha 01", "Escolha 02"] } };
        }
        // this.editedItem = this.resetModel()
    };
    VagaRHQuestionarioCls.prototype.btConfirmar = function () {
        if (this.status == StatusFormType.incluindo) {
            this.registros.push(__assign({}, this.editedItem));
        }
        else if (this.status == StatusFormType.excluindo) {
            this.registros.splice(this.indexItem, 1);
        }
        else if (this.status == StatusFormType.editando) {
            this.registros[this.indexItem] = __assign({}, this.editedItem);
        }
        this.keyRefresh++;
        this.dialog = false;
        this.status = StatusFormType.inicial;
        this.editedItem = this.resetModel();
        this.$emit('input', __spreadArrays(this.registros));
    };
    Object.defineProperty(VagaRHQuestionarioCls.prototype, "validarFormulario", {
        get: function () {
            var retorno = true;
            if (this.editedItem.configuracao.opcoes.length == 0 && (this.editedItem.configuracao.tipo == PerguntaType.Escolha || this.editedItem.configuracao.tipo == PerguntaType.MultiplaEscolha)) {
                retorno = false;
            }
            if (this.editedItem.configuracao.tamanho == 0 && this.editedItem.configuracao.tipo == PerguntaType.Texto) {
                retorno = false;
            }
            return retorno;
        },
        enumerable: false,
        configurable: true
    });
    VagaRHQuestionarioCls.prototype.editItem = function (item) {
        this.editedItem = item;
        this.indexItem = this.registros.indexOf(item);
        this.status = StatusFormType.editando;
        this.dialog = true;
    };
    VagaRHQuestionarioCls.prototype.deleteItem = function (item) {
        this.editedItem = item;
        this.indexItem = this.registros.indexOf(item);
        this.status = StatusFormType.excluindo;
        this.dialog = true;
    };
    __decorate([
        Prop({ default: false })
    ], VagaRHQuestionarioCls.prototype, "disabled", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], VagaRHQuestionarioCls.prototype, "value", void 0);
    __decorate([
        Watch('value')
    ], VagaRHQuestionarioCls.prototype, "changeValue", null);
    VagaRHQuestionarioCls = __decorate([
        Component({
            components: {
                questionarioPergunta: questionarioPergunta
            }
        })
    ], VagaRHQuestionarioCls);
    return VagaRHQuestionarioCls;
}(Vue));
export default VagaRHQuestionarioCls;
