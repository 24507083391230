import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
var Alerta = /** @class */ (function (_super) {
    __extends(Alerta, _super);
    function Alerta() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Alerta.prototype, "TipoAlertaMensagemType", {
        get: function () {
            return TipoAlertaMensagemType;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], Alerta.prototype, "mensagemAlerta", void 0);
    Alerta = __decorate([
        Component
    ], Alerta);
    return Alerta;
}(Vue));
export default Alerta;
