import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { StatusStoreModule } from '@/store/modules/StatusStore';
var curriculumDetalhe = /** @class */ (function (_super) {
    __extends(curriculumDetalhe, _super);
    function curriculumDetalhe() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Habilidade',
            mensagem: '',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.formValid = false;
        _this.dialog = false;
        _this.editedItem = {
            descricao: '',
            nivel: 0
        };
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            {
                text: 'Descrição',
                align: 'left',
                sortable: false,
                value: 'descricao'
            },
            { text: 'Nível', value: 'nivel' },
            { text: 'Ações', value: 'actions', sortable: false },
        ];
        _this.registros = [];
        return _this;
    }
    Object.defineProperty(curriculumDetalhe.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(curriculumDetalhe.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.incluindo ? 'Incluir' : this.status == StatusFormType.editando ? "Editar" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    curriculumDetalhe.prototype.mounted = function () {
        this.getDetalhe();
    };
    curriculumDetalhe.prototype.btCancelar = function () {
        this.dialog = false;
    };
    curriculumDetalhe.prototype.btIncluir = function () {
        this.status = StatusFormType.incluindo;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                descricao: 'teste descricao',
                nivel: 3
            };
        }
        else {
            this.editedItem = {
                descricao: '',
                nivel: 0
            };
        }
    };
    curriculumDetalhe.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n          setHabilidade(habilidade: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n            ok\n            mensagem\n          }\n        ";
                this.clsApollo.mutation(this, mutation, 'setHabilidade', this.mensagemAlerta, 'Atualizando Habilidade...').then(function (rs) {
                    if (rs.ok) {
                        _this.dialog = false;
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else {
            var mutation = "\n        delHabilidade(habilidade: { idHabilidade: " + this.editedItem.idHabilidade + " } ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delHabilidade', this.mensagemAlerta, 'Excluindo Habilidade').then(function (rs) {
                if (rs.ok) {
                    _this.dialog = false;
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
            });
        }
    };
    curriculumDetalhe.prototype.editItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.editando;
        this.dialog = true;
    };
    curriculumDetalhe.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
        this.dialog = true;
    };
    curriculumDetalhe.prototype.getDetalhe = function () {
        var _this = this;
        this.mensagemAlerta.mensagem = 'PequisandoHabilidade';
        var query = "\n      getHabilidade {\n        idHabilidade\n        descricao\n        nivel\n      }\n    ";
        this.clsApollo.query(this, query, 'getHabilidade', this.mensagemAlerta).then(function (rs) {
            StatusStoreModule.refreshStatus(_this);
            _this.registros = rs;
        });
    };
    curriculumDetalhe = __decorate([
        Component
    ], curriculumDetalhe);
    return curriculumDetalhe;
}(Vue));
export default curriculumDetalhe;
