import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import inputChip from '../components/inputChip.vue';
import { validadoresFrontEnd } from 'zlib-utils';
var Teste = /** @class */ (function (_super) {
    __extends(Teste, _super);
    function Teste() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.validadoresFrontEnd = validadoresFrontEnd;
        return _this;
    }
    Teste = __decorate([
        Component({
            components: {
                inputChip: inputChip
            }
        })
    ], Teste);
    return Teste;
}(Vue));
export default Teste;
