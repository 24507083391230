import { __awaiter, __decorate, __extends, __generator } from "tslib";
import ClsApolloClient from '@/utils/ClsApolloClient';
import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators';
import store from '..';
var StatusStore = /** @class */ (function (_super) {
    __extends(StatusStore, _super);
    function StatusStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ContatosDigitais = false;
        _this.Cursos = false;
        _this.DadosPessoais = false;
        _this.Experiencias = false;
        _this.Formacao = false;
        _this.Habilidades = false;
        _this.Perfil = false;
        _this.Selfie = false;
        _this.Qtd = 0;
        return _this;
    }
    StatusStore.prototype.status = function (status) {
        this.ContatosDigitais = status.ContatosDigitais;
        this.Cursos = status.Cursos;
        this.DadosPessoais = status.DadosPessoais;
        this.Experiencias = status.Experiencias;
        this.Formacao = status.Formacao;
        this.Habilidades = status.Habilidades;
        this.Perfil = status.Perfil;
        this.Selfie = status.Selfie;
        this.Qtd = 0;
        if (!status.ContatosDigitais)
            this.Qtd++;
        if (!status.Cursos)
            this.Qtd++;
        if (!status.DadosPessoais)
            this.Qtd++;
        if (!status.Experiencias)
            this.Qtd++;
        if (!status.Formacao)
            this.Qtd++;
        if (!status.Habilidades)
            this.Qtd++;
        if (!status.Perfil)
            this.Qtd++;
        if (!status.Selfie)
            this.Qtd++;
    };
    StatusStore.prototype.refreshStatus = function (payload) {
        return __awaiter(this, void 0, void 0, function () {
            var clsApolloClient, status, rs;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        clsApolloClient = new ClsApolloClient();
                        status = "\n      status {\n        ContatosDigitais\n        Cursos\n        DadosPessoais\n        Experiencias\n        Formacao\n        Habilidades\n        Selfie\n        Perfil\n      }\n    ";
                        return [4 /*yield*/, clsApolloClient.query(payload, status, 'status')];
                    case 1:
                        rs = _a.sent();
                        return [2 /*return*/, rs];
                }
            });
        });
    };
    __decorate([
        Mutation
    ], StatusStore.prototype, "status", null);
    __decorate([
        Action({ commit: 'status', rawError: true })
    ], StatusStore.prototype, "refreshStatus", null);
    StatusStore = __decorate([
        Module({ dynamic: true, store: store, name: 'status' })
    ], StatusStore);
    return StatusStore;
}(VuexModule));
export var StatusStoreModule = getModule(StatusStore, store);
