import _, { reject } from 'lodash';
import gql from 'graphql-tag';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
var ClsApolloClient = /** @class */ (function () {
    function ClsApolloClient() {
    }
    ClsApolloClient.prototype.query = function (self, query, nomeObjRetorno, mensagemAlerta) {
        return new Promise(function (resolve) {
            return self.$apollo.query({
                query: gql("query { " + query + " }"),
                fetchPolicy: "no-cache"
            }).then(function (rs) {
                if (!rs || rs.errors || rs.error) {
                    if (mensagemAlerta) {
                        mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                        mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.';
                        mensagemAlerta.exibir = true;
                        mensagemAlerta.titulo = 'ERRO!!!';
                        resolve();
                    }
                    else {
                        reject(rs.error || rs.erros || rs || 'Erro...');
                    }
                }
                else {
                    var objeto = _.find(rs, nomeObjRetorno) || {};
                    resolve(objeto[nomeObjRetorno]);
                }
            }).catch(function (err) {
                if (mensagemAlerta) {
                    mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.';
                    mensagemAlerta.exibir = true;
                    mensagemAlerta.titulo = 'ERRO!!!';
                    resolve();
                }
                else {
                    reject(err.message || 'Erro...');
                }
            });
        });
    };
    ClsApolloClient.prototype.mutation = function (self, mutation, nomeObjRetorno, mensagemAlerta, mensagem) {
        if (mensagemAlerta && mensagem) {
            mensagemAlerta.mensagem = mensagem;
            mensagemAlerta.exibir = true;
        }
        return new Promise(function (resolve, reject) {
            return self.$apollo.mutate({
                mutation: gql("mutation { " + mutation + " }")
            }).then(function (rs) {
                if (!rs || rs.errors || rs.error) {
                    if (mensagemAlerta) {
                        mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                        mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.';
                        mensagemAlerta.exibir = true;
                        mensagemAlerta.titulo = 'ERRO!!!';
                        resolve();
                    }
                    else {
                        reject(rs.error || rs.erros || rs || 'Erro...');
                    }
                }
                else {
                    if (mensagemAlerta && rs.mensagem && !rs.ok) {
                        mensagemAlerta.mensagem = rs.mensagem;
                        mensagemAlerta.exibir = true;
                    }
                    else if (mensagemAlerta && rs.ok) {
                        mensagemAlerta.exibir = false;
                    }
                    var objeto = _.find(rs, nomeObjRetorno) || {};
                    // Retornar o nomeObjRetorno
                    resolve(objeto[nomeObjRetorno]);
                }
            }).catch(function (err) {
                if (mensagemAlerta) {
                    mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    mensagemAlerta.mensagem = 'Erro no Servidor. Consulte Suporte.';
                    mensagemAlerta.exibir = true;
                    mensagemAlerta.titulo = 'ERRO!!!';
                    resolve();
                }
                else {
                    reject(err.message || 'Erro...');
                }
            });
        });
    };
    return ClsApolloClient;
}());
export default ClsApolloClient;
