import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Alerta from '../../components/alert.vue';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '../../utils/ClsApolloClient';
import ClsUtils from 'zlib-utils/ClsUtils';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { validadoresFrontEnd } from 'zlib-utils';
var clsReenviarSenha = /** @class */ (function (_super) {
    __extends(clsReenviarSenha, _super);
    function clsReenviarSenha() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.numero01 = Math.floor(Math.random() * 5);
        _this.numero02 = Math.floor(Math.random() * 5);
        _this.envioConcluido = false;
        _this.clsUtils = new ClsUtils();
        _this.formValid = false;
        _this.mensagemAlerta = {
            titulo: 'Senha',
            mensagem: 'Enviando...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.registro = {
            email: EMDESENVOLVIMENTO ? 'zanatta@peopledivinopolis.com.br' : '',
            soma: ''
        };
        return _this;
    }
    Object.defineProperty(clsReenviarSenha.prototype, "validadoresFrontEnd", {
        get: function () {
            return validadoresFrontEnd;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(clsReenviarSenha.prototype, "podeEnviar", {
        get: function () {
            return this.clsUtils.emailValido(this.registro.email) && this.registro.soma == (this.numero01 + this.numero02).toString();
        },
        enumerable: false,
        configurable: true
    });
    clsReenviarSenha.prototype.enviarSenha = function () {
        var _this = this;
        // @ts-ignore
        if (this.$refs.form.validate()) {
            var mutation = "\n      enviarSenha (email: \"" + this.registro.email + "\") {\n        ok\n        mensagem\n      }    \n      ";
            var clsApolloClient = new ClsApolloClient();
            clsApolloClient.mutation(this, mutation, 'enviarSenha', this.mensagemAlerta).then(function () {
                _this.envioConcluido = true;
            });
        }
    };
    clsReenviarSenha = __decorate([
        Component({
            components: {
                Alerta: Alerta
            }
        })
    ], clsReenviarSenha);
    return clsReenviarSenha;
}(Vue));
export default clsReenviarSenha;
