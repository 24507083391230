import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify);
export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: colors.blue.darken2,
                secondary: colors.purple.lighten1,
                accent: colors.purple.lighten3,
                error: colors.red.darken4,
                success: colors.teal.lighten1,
                anchor: colors.teal.lighten3,
                info: colors.teal.lighten5,
                warning: colors.teal.darken4,
                botaoAnterior: colors.grey.darken3
            },
            dark: {
                primary: colors.green.base,
                secondary: colors.green.base,
                accent: colors.green.base,
                error: colors.green.base,
                success: colors.green.base,
                anchor: colors.green.base,
                info: colors.green.base,
                warning: colors.green.base,
                botaoAnterior: colors.grey.darken3
            },
        },
    },
});
/*

  theme: {
    themes: {
      dark: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },

  */
