import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import curriculumDetalhe from '../../components/curriculumDetalhe.vue';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.SetupDetalhe = {
            titulo: 'Cursos Complementares',
            metodoGraphQL: 'Curso',
            objetoGraphQL: 'curso',
            campoChave: 'idCurso',
            mensagemSemRegistro: 'Sem Cursos Cadastrados',
            titulosCampos: {
                instituicao: 'Instituição',
                periodo: 'Período',
                descricao: 'Título',
                resumo: 'Descrição'
            }
        };
        return _this;
    }
    app = __decorate([
        Component({
            components: {
                curriculumDetalhe: curriculumDetalhe
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
