import { __assign } from "tslib";
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient } from 'vue-cli-plugin-apollo/graphql-client';
import { ENDPOINT_GRAPHQL } from './config/backend/emDesenvolvimento';
// Install the vue plugin
Vue.use(VueApollo);
// Name of the localStorage item
export var AUTH_TOKEN = 'hashfc20';
// Http endpoint
var httpEndpoint = ENDPOINT_GRAPHQL;
// Config
var defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint: httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
    wsEndpoint: '',
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,
};
// Call this in the Vue app file
export function createProvider(options) {
    if (options === void 0) { options = {}; }
    // Create apollo client
    var _a = createApolloClient(__assign(__assign({}, defaultOptions), options)), apolloClient = _a.apolloClient, wsClient = _a.wsClient;
    // @ts-ignore
    apolloClient.wsClient = wsClient;
    // Create vue apollo provider
    var apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
            // fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler: function (error) {
            // eslint-disable-next-line no-console
            console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message);
        },
    });
    return apolloProvider;
}
/*
// Manually call this when user log in
export async function onLogin ( apolloClient: any, token: string ) {
  if ( typeof localStorage !== 'undefined' && token ) {
    localStorage.setItem( AUTH_TOKEN, token )
  }
  if ( apolloClient.wsClient ) restartWebsockets( apolloClient.wsClient )
  try {
    await apolloClient.resetStore()
  } catch ( e ) {
    // eslint-disable-next-line no-console
    console.log( '%cError on cache reset (login)', 'color: orange;', e.message )
  }
}

// Manually call this when user log out
export async function onLogout ( apolloClient: any ) {
  if ( typeof localStorage !== 'undefined' ) {
    localStorage.removeItem( AUTH_TOKEN )
  }
  if ( apolloClient.wsClient ) restartWebsockets( apolloClient.wsClient )
  try {
    await apolloClient.resetStore()
  } catch ( e ) {
    // eslint-disable-next-line no-console
    console.log( '%cError on cache reset (logout)', 'color: orange;', e.message )
  }
}
*/ 
