import { __assign, __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { validadoresFrontEnd } from 'zlib-utils';
// @ts-ignore
import { mask } from 'vue-the-mask';
import { PerguntaType } from '@/interfaces/backend/RHInterfaces';
export var moneyMask = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false /* doesn't work with directive */
};
var Pergunta = /** @class */ (function (_super) {
    __extends(Pergunta, _super);
    function Pergunta() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.registro = {
            pergunta: '',
            configuracao: {
                tipo: PerguntaType.MultiplaEscolha,
                tamanho: 0,
                opcoes: []
            }
        };
        /*
          private btSalvar (): void {
            this.$emit( 'input', { ...this.registro } )
          }
        */
        // CRUD Escolhas
        _this.tmpOpcao = "";
        return _this;
    }
    Pergunta.prototype.changeValue = function (novo) {
        this.registro = __assign({}, novo);
    };
    Pergunta.prototype.mounted = function () {
        this.changeValue(this.value);
    };
    Object.defineProperty(Pergunta.prototype, "PerguntaType", {
        get: function () {
            return PerguntaType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Pergunta.prototype, "tipos", {
        get: function () {
            return [{
                    idTipo: PerguntaType.Texto,
                    descricao: 'Texto'
                },
                {
                    idTipo: PerguntaType.SimNao,
                    descricao: 'Sim / Não'
                },
                {
                    idTipo: PerguntaType.Escolha,
                    descricao: 'Escolha'
                },
                {
                    idTipo: PerguntaType.MultiplaEscolha,
                    descricao: 'Multipla Escolha'
                },
                {
                    idTipo: PerguntaType.Numero,
                    descricao: 'Número'
                },
                {
                    idTipo: PerguntaType.Monetario,
                    descricao: 'Valor Monetário'
                }];
        },
        enumerable: false,
        configurable: true
    });
    Pergunta.prototype.addEscolha = function () {
        if (this.tmpOpcao.length > 0) {
            this.registro.configuracao.opcoes.push(this.tmpOpcao);
            this.tmpOpcao = '';
        }
    };
    Pergunta.prototype.delEscolha = function (index) {
        this.tmpOpcao = this.registro.configuracao.opcoes[index];
        this.registro.configuracao.opcoes.splice(index, 1);
    };
    Pergunta.prototype.inputEvent = function (oque) {
        this.$emit('input', __assign({}, this.registro));
    };
    Pergunta.prototype.inputEventTipo = function (oque) {
        var tmpRegistro = {
            pergunta: this.registro.pergunta,
            configuracao: {
                tipo: this.registro.configuracao.tipo,
                tamanho: 0,
                opcoes: []
            }
        };
        // Limpa o Registro
        if (this.registro.configuracao.tipo == PerguntaType.Texto) {
            tmpRegistro.configuracao.tamanho = this.registro.configuracao.tamanho;
        }
        else if (this.registro.configuracao.tipo == PerguntaType.MultiplaEscolha || this.registro.configuracao.tipo == PerguntaType.Escolha) {
            tmpRegistro.configuracao.opcoes = this.registro.configuracao.opcoes;
        }
        this.$emit('input', __assign({}, tmpRegistro));
    };
    __decorate([
        Prop({
            default: function () {
                return {
                    pergunta: '',
                    configuracao: {
                        tipo: PerguntaType.MultiplaEscolha,
                        tamanho: 0,
                        opcoes: []
                    }
                };
            }
        })
    ], Pergunta.prototype, "value", void 0);
    __decorate([
        Watch('value')
    ], Pergunta.prototype, "changeValue", null);
    __decorate([
        Prop({ default: false })
    ], Pergunta.prototype, "disabled", void 0);
    Pergunta = __decorate([
        Component({
            directives: {
                mask: mask
            }
        })
    ], Pergunta);
    return Pergunta;
}(Vue));
export default Pergunta;
