import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { StatusStoreModule } from '@/store/modules/StatusStore';
var curriculumDetalhe = /** @class */ (function (_super) {
    __extends(curriculumDetalhe, _super);
    function curriculumDetalhe() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: _this.setup.titulo,
            mensagem: '',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.formValid = false;
        _this.dialog = false;
        _this.editedItem = {};
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            {
                text: _this.setup.titulosCampos.instituicao,
                align: 'left',
                sortable: false,
                value: 'instituicao',
                visibility: 'hidden-sm-and-down'
            },
            { text: _this.setup.titulosCampos.descricao, value: 'descricao' },
            { text: _this.setup.titulosCampos.periodo, value: 'periodo' },
            { text: _this.setup.titulosCampos.resumo, value: 'resumo' },
            { text: 'Ações', value: 'actions', sortable: false },
        ];
        _this.registros = [];
        return _this;
    }
    Object.defineProperty(curriculumDetalhe.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(curriculumDetalhe.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.incluindo ? 'Incluir' : this.status == StatusFormType.editando ? "Editar" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    curriculumDetalhe.prototype.mounted = function () {
        this.getDetalhe();
    };
    curriculumDetalhe.prototype.btCancelar = function () {
        this.dialog = false;
    };
    curriculumDetalhe.prototype.btIncluir = function () {
        this.status = StatusFormType.incluindo;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                instituicao: 'teste instituicao',
                periodo: 'teste periodo',
                descricao: 'teste descricao',
                resumo: 'teste resumo'
            };
        }
        else {
            this.editedItem = {
                instituicao: '',
                periodo: '',
                descricao: '',
                resumo: ''
            };
        }
    };
    curriculumDetalhe.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n          set" + this.setup.metodoGraphQL + "(" + this.setup.objetoGraphQL + ": " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n            ok\n            mensagem\n          }\n        ";
                this.clsApollo.mutation(this, mutation, 'set'.concat(this.setup.metodoGraphQL), this.mensagemAlerta, 'Atualizando '.concat(this.setup.titulo).concat('....')).then(function (rs) {
                    if (rs.ok) {
                        _this.dialog = false;
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else {
            var mutation = "\n        del" + this.setup.metodoGraphQL + "(" + this.setup.objetoGraphQL + ": { " + this.setup.campoChave + ": " + this.editedItem[this.setup.campoChave] + " } ) {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'del'.concat(this.setup.metodoGraphQL), this.mensagemAlerta, 'Excluindo '.concat(this.setup.titulo).concat('....')).then(function (rs) {
                if (rs.ok) {
                    _this.dialog = false;
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
            });
        }
    };
    curriculumDetalhe.prototype.editItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.editando;
        this.dialog = true;
    };
    curriculumDetalhe.prototype.deleteItem = function (item) {
        this.editedItem = Object.assign({}, item);
        this.status = StatusFormType.excluindo;
        this.dialog = true;
    };
    curriculumDetalhe.prototype.getDetalhe = function () {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando'.concat(this.setup.titulo).concat('....');
        var query = "\n      get" + this.setup.metodoGraphQL + " {\n        " + this.setup.campoChave + "\n        instituicao\n        periodo\n        descricao\n        resumo\n      }\n    ";
        this.clsApollo.query(this, query, 'get'.concat(this.setup.metodoGraphQL), this.mensagemAlerta).then(function (rs) {
            StatusStoreModule.refreshStatus(_this);
            _this.registros = rs;
        });
    };
    __decorate([
        Prop()
    ], curriculumDetalhe.prototype, "setup", void 0);
    curriculumDetalhe = __decorate([
        Component
    ], curriculumDetalhe);
    return curriculumDetalhe;
}(Vue));
export default curriculumDetalhe;
