import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import Alerta from '../../components/alert.vue';
import { VueEditor } from "vue2-editor";
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsUtils from 'zlib-utils/ClsUtils';
import { StatusStoreModule } from '@/store/modules/StatusStore';
import { CUSTOMTOOLBAR } from '@/config/CustomToolBar';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Perfil',
            mensagem: 'Obtendo Perfil. Aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.CUSTOMTOOLBAR = CUSTOMTOOLBAR;
        _this.registro = {
            perfil: '',
        };
        _this.tmpPerfil = '';
        return _this;
    }
    Object.defineProperty(app.prototype, "StatusFormType", {
        get: function () { return StatusFormType; },
        enumerable: false,
        configurable: true
    });
    app.prototype.mounted = function () {
        this.getDados();
    };
    app.prototype.getDados = function () {
        var _this = this;
        var query = "\n      getPerfil {\n        perfil\n      }    \n    ";
        this.mensagemAlerta.exibir = true;
        var clsApolloClient = new ClsApolloClient();
        clsApolloClient.query(this, query, 'getPerfil', this.mensagemAlerta).then(function (rs) {
            if (rs) {
                _this.registro = rs;
                _this.tmpPerfil = rs.perfil;
            }
            _this.mensagemAlerta.exibir = false;
        });
    };
    app.prototype.gravar = function () {
        var _this = this;
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.mensagemAlerta.mensagem = 'Gravando Perfil...';
            this.mensagemAlerta.exibir = true;
            this.status = StatusFormType.processando;
            this.confirmarPerfil().then(function (rs) {
                _this.mensagemAlerta.mensagem = rs.mensagem;
                StatusStoreModule.refreshStatus(_this);
                if (rs.ok) {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.tmpPerfil = _this.registro.perfil;
                    _this.status = StatusFormType.inicial;
                }
                else {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                console.log('ERRO....', err);
            });
        }
    };
    app.prototype.confirmarPerfil = function () {
        var clsUtils = new ClsUtils();
        var mutation = "\n      setPerfil(dados: " + clsUtils.ConverterEmGql(this.registro) + ") {\n        ok\n        mensagem\n      }\n    ";
        var clsApolloClient = new ClsApolloClient();
        return clsApolloClient.mutation(this, mutation, 'setPerfil', this.mensagemAlerta);
    };
    app = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                VueEditor: VueEditor
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
