import { __decorate, __extends } from "tslib";
import { StatusStoreModule } from '@/store/modules/StatusStore';
import Vue from 'vue';
import Component from 'vue-class-component';
import DadosPessoais from '@/views/curriculum/DadosPessoais';
import Perfil from '@/views/curriculum/Perfil';
import ContatosDigitais from '@/views/curriculum/ContatosDigitais';
import Cursos from '@/views/curriculum/Cursos.vue';
import Formacao from '@/views/curriculum/Formacao.vue';
import Habilidades from '@/views/curriculum/Habilidades.vue';
import Experiencias from '@/views/curriculum/Experiencias.vue';
var StepByStepCls = /** @class */ (function (_super) {
    __extends(StepByStepCls, _super);
    function StepByStepCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.step = 1;
        return _this;
    }
    Object.defineProperty(StepByStepCls.prototype, "status", {
        get: function () {
            return StatusStoreModule;
        },
        enumerable: false,
        configurable: true
    });
    StepByStepCls = __decorate([
        Component({
            components: {
                DadosPessoais: DadosPessoais,
                Perfil: Perfil,
                ContatosDigitais: ContatosDigitais,
                Cursos: Cursos,
                Formacao: Formacao,
                Habilidades: Habilidades,
                Experiencias: Experiencias
            }
        })
    ], StepByStepCls);
    return StepByStepCls;
}(Vue));
export default StepByStepCls;
