import { TipoUsuarioRole } from '@/interfaces/backend/UsuarioInterfaces';
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/Testes'
export var PAGINA_INICIAL_EMDESENVOLVIMENTO = '/StepByStep';
//export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/'
// export const PAGINA_INICIAL_EMDESENVOLVIMENTO = '/VagasRH'
export var MENU = [
    {
        icone: 'mdi-chevron-down',
        iconeAlt: 'mdi-chevron-up',
        texto: 'Meu Curriculum',
        expandido: true,
        permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO],
        filhos: [
            { icone: 'mdi-account-details', texto: 'Curriculum', link: '/StepByStep', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], },
            { icone: 'mdi-account-circle', texto: 'Selfie', link: '/Selfie', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], },
            { icone: 'mdi-download', texto: 'Download', link: '/Download', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], }
        ],
    },
    {
        icone: 'mdi-chevron-down',
        iconeAlt: 'mdi-chevron-up',
        texto: 'RH',
        expandido: true,
        permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH],
        filhos: [
            { icone: 'mdi-account-details', texto: 'Empresas', link: '/Empresas', corBotao: 'white', corIcone: 'orange darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH], },
            { icone: 'mdi-account-circle', texto: 'Vagas RH', link: '/VagasRH', corBotao: 'white', corIcone: 'orange darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH], }
        ],
    },
    { icone: 'mdi-lock-reset', texto: 'Alterar Senha', link: '/AlterarSenha', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], },
    { icone: 'mdi-post-outline', texto: 'Vagas', link: '/Vagas', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO], },
    { icone: 'mdi-logout', texto: 'Sair', link: '/Logout', corBotao: 'white', corIcone: 'blue darken-3', permissoes: [TipoUsuarioRole.ADMIN, TipoUsuarioRole.RH, TipoUsuarioRole.USUARIO, TipoUsuarioRole.ALUNO] }
];
