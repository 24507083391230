import { TIPOVAGADESCRICAO } from '@/interfaces/backend/UsuarioInterfaces';
import _ from 'lodash';
export var StatusFormType;
(function (StatusFormType) {
    StatusFormType[StatusFormType["inicial"] = 0] = "inicial";
    StatusFormType[StatusFormType["valido"] = 1] = "valido";
    StatusFormType[StatusFormType["erro"] = 2] = "erro";
    StatusFormType[StatusFormType["processando"] = 3] = "processando";
    StatusFormType[StatusFormType["editando"] = 4] = "editando";
    StatusFormType[StatusFormType["incluindo"] = 5] = "incluindo";
    StatusFormType[StatusFormType["excluindo"] = 6] = "excluindo";
})(StatusFormType || (StatusFormType = {}));
export var getTIPOVAGADESCRICAO = function (tipo) {
    return _.find(TIPOVAGADESCRICAO, { tipo: tipo });
};
