import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO, TAMANHO_MAXIMO_PAYLOAD } from '@/config/backend/emDesenvolvimento';
import { StatusStoreModule } from '@/store/modules/StatusStore';
import ImageUploader from 'vue-image-upload-resize';
import { VueEditor } from "vue2-editor";
import Alerta from '../../components/alert.vue';
// @ts-ignore
import { mask } from 'vue-the-mask';
import { CUSTOMTOOLBAR } from '@/config/CustomToolBar';
var EmpresasCls = /** @class */ (function (_super) {
    __extends(EmpresasCls, _super);
    function EmpresasCls() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Empresa',
            mensagem: '',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.search = '';
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.formValid = false;
        _this.dialog = false;
        _this.editedItem = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            {
                text: 'Nome',
                align: 'left',
                sortable: true,
                value: 'nome'
            },
            {
                text: 'Cidade',
                align: 'left',
                sortable: true,
                value: 'cidade'
            },
            {
                text: 'UF',
                align: 'left',
                sortable: true,
                value: 'uf'
            },
            { text: 'Ações', value: 'actions', sortable: false },
        ];
        _this.registros = [];
        _this.CUSTOMTOOLBAR = CUSTOMTOOLBAR;
        return _this;
    }
    Object.defineProperty(EmpresasCls.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EmpresasCls.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.incluindo ? 'Incluir' : this.status == StatusFormType.editando ? "Editar" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    EmpresasCls.prototype.mounted = function () {
        this.getDetalhe();
    };
    EmpresasCls.prototype.resetModel = function () {
        return {
            idEmpresa: 0,
            idUsuario: 0,
            nome: '',
            cep: '',
            endereco: '',
            bairro: '',
            cidade: '',
            uf: '',
            fone: '',
            perfil: '',
            whatsapp: '',
            email: '',
            twitter: '',
            facebook: '',
            instagram: '',
            linkedin: '',
            logo: ''
        };
    };
    EmpresasCls.prototype.btCancelar = function () {
        this.dialog = false;
        this.status = StatusFormType.inicial;
    };
    EmpresasCls.prototype.btIncluir = function () {
        this.status = StatusFormType.incluindo;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                idEmpresa: 0,
                idUsuario: 0,
                nome: 'nome',
                cep: 'cep',
                endereco: 'endereco',
                bairro: 'bairro',
                cidade: 'cidade',
                uf: 'uf',
                fone: 'fone',
                perfil: 'perfil',
                whatsapp: 'whatsapp',
                email: 'email',
                twitter: 'twitter',
                facebook: 'facebook',
                instagram: 'instagram',
                linkedin: 'linkedin',
                logo: ''
            };
        }
        else {
            this.editedItem = this.resetModel();
        }
    };
    EmpresasCls.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n          setEmpresa(empresa: " + clsUtils.ConverterEmGql(this.editedItem) + " ) {\n            ok\n            mensagem\n          }\n        ";
                this.clsApollo.mutation(this, mutation, 'setEmpresa', this.mensagemAlerta, 'Atualizando Empresa...').then(function (rs) {
                    if (rs.ok) {
                        _this.dialog = false;
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else {
            var mutation = "\n        delEmpresa(idEmpresa: " + this.editedItem.idEmpresa + ") {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delEmpresa', this.mensagemAlerta, 'Excluindo Empresa').then(function (rs) {
                if (rs.ok) {
                    _this.dialog = false;
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
            });
        }
    };
    EmpresasCls.prototype.editItem = function (item) {
        var _this = this;
        this.getDados(item.idEmpresa).then(function (rs) {
            if (rs) {
                _this.editedItem = Object.assign({}, rs);
                _this.status = StatusFormType.editando;
                _this.dialog = true;
            }
        });
    };
    EmpresasCls.prototype.deleteItem = function (item) {
        var _this = this;
        this.getDados(item.idEmpresa).then(function (rs) {
            if (rs) {
                _this.editedItem = Object.assign({}, rs);
                _this.status = StatusFormType.excluindo;
                _this.dialog = true;
            }
        });
    };
    EmpresasCls.prototype.getDados = function (idEmpresa) {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Empresa';
        var query = "\n      getEmpresa (idEmpresa: " + idEmpresa + ") {\n        idEmpresa\n        nome\n        cep\n        endereco\n        bairro\n        cidade\n        uf\n        fone\n        perfil\n        whatsapp\n        email\n        twitter\n        facebook\n        instagram\n        linkedin\n        logo\n      }\n    ";
        return this.clsApollo.query(this, query, 'getEmpresa', this.mensagemAlerta).then(function (rs) {
            StatusStoreModule.refreshStatus(_this);
            return rs;
        });
    };
    EmpresasCls.prototype.getDetalhe = function () {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Empresa';
        var query = "\n      getEmpresas (search: \"" + this.search + "\") {\n        idEmpresa\n        nome\n        cidade\n        uf\n      }\n    ";
        this.clsApollo.query(this, query, 'getEmpresas', this.mensagemAlerta).then(function (rs) {
            _this.registros = rs;
            _this.mensagemAlerta.exibir = false;
        });
    };
    Object.defineProperty(EmpresasCls.prototype, "classePadrao", {
        get: function () {
            return 'py-1 my-0';
        },
        enumerable: false,
        configurable: true
    });
    EmpresasCls.prototype.onInputLogo = function (imagem) {
        if (imagem.length > TAMANHO_MAXIMO_PAYLOAD) {
            this.mensagemAlerta.mensagem = "Imagem Muito Grande. Diminua a resolução da Câmera!";
            this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
            this.mensagemAlerta.exibir = true;
        }
        else {
            this.editedItem.logo = imagem;
        }
    };
    EmpresasCls = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                VueEditor: VueEditor,
                ImageUploader: ImageUploader
            },
            directives: {
                mask: mask
            }
        })
    ], EmpresasCls);
    return EmpresasCls;
}(Vue));
export default EmpresasCls;
