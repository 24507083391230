import { __assign, __decorate, __extends, __spreadArrays } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Alerta from '../../components/alert.vue';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { ISO_TO_USUARIO_DATA } from '@/utils/FormatarDados';
import { getTIPOVAGADESCRICAO } from '@/utils/dataTypes';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { StatusStoreModule } from '@/store/modules/StatusStore';
import vagasCandidatar from './VagasCandidatar.vue';
var Vagas = /** @class */ (function (_super) {
    __extends(Vagas, _super);
    function Vagas() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.help = false;
        _this.rsHelp = {
            empresa: {
                nome: "",
                perfil: "",
                cidade: "",
                uf: ""
            }
        };
        _this.getTIPOVAGADESCRICAO = getTIPOVAGADESCRICAO;
        _this.ISO_TO_USUARIO_DATA = ISO_TO_USUARIO_DATA;
        _this.registros = [];
        _this.keyRefresh = 0;
        _this.clsApollo = new ClsApolloClient();
        _this.search = "";
        _this.mensagemAlerta = {
            titulo: 'Vagas',
            mensagem: 'Obtendo Vagas. Aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        return _this;
    }
    Object.defineProperty(Vagas.prototype, "LoginStoreModule", {
        get: function () { return LoginStoreModule; },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Vagas.prototype, "StatusStoreModule", {
        get: function () { return StatusStoreModule; },
        enumerable: false,
        configurable: true
    });
    Vagas.prototype.mounted = function () {
        this.getDados();
    };
    Vagas.prototype.getDados = function () {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Vaga';
        var query = "\n      getVagasDisponiveis (search: \"" + this.search + "\") {\n        idVaga\n        descritivo\n        dataLimite\n        horario\n        remuneracao\n        tipo\n        titulo\n        candidato\n        empresa {\n          nome\n          perfil\n          cidade\n          uf\n          logo\n        }\n        beneficios {\n          descricao\n        }\n      }\n    ";
        this.clsApollo.query(this, query, 'getVagasDisponiveis', this.mensagemAlerta).then(function (rs) {
            _this.registros = __spreadArrays(rs);
            _this.keyRefresh++;
        });
    };
    Vagas.prototype.btHelp = function (item) {
        this.rsHelp.empresa = __assign({}, item.empresa);
        this.help = true;
    };
    Vagas = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                vagasCandidatar: vagasCandidatar
            }
        })
    ], Vagas);
    return Vagas;
}(Vue));
export default Vagas;
