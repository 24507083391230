import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Alerta from '../../components/alert.vue';
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '../../utils/ClsApolloClient';
import ClsUtils from 'zlib-utils/ClsUtils';
import { LoginStoreModule } from '@/store/modules/LoginStore';
import { AUTH_TOKEN } from '../../vue-apollo';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { PAGINA_INICIAL_EMDESENVOLVIMENTO } from '@/config/Menu';
import { StatusStoreModule } from '@/store/modules/StatusStore';
import store from '@/store';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clsApolloClient = new ClsApolloClient();
        _this.mensagemAlerta = {
            titulo: 'Login',
            mensagem: 'Verificando Usuário e Senha...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.exibirSenha = false;
        _this.registro = {
            // login: EMDESENVOLVIMENTO ? 'ZanattaFleek' : '',
            //senha: EMDESENVOLVIMENTO ? 'Teste123' : '',
            // Login de RH
            login: EMDESENVOLVIMENTO ? 'RHFleekCursos' : '',
            senha: EMDESENVOLVIMENTO ? 'Fleek2020Senha' : ''
            // Login de Usuário
            // login: EMDESENVOLVIMENTO ? 'Gabrielaczanatta' : '',
            // senha: EMDESENVOLVIMENTO ? 'Sophie0209' : ''
        };
        return _this;
    }
    app.prototype.mounted = function () {
        var tmpToken = localStorage.getItem(AUTH_TOKEN) || '';
        if (tmpToken && !LoginStoreModule.logado) {
            this.logarPorToken();
        }
    };
    Object.defineProperty(app.prototype, "validadoresFrontEnd", {
        get: function () {
            return validadoresFrontEnd;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(app.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    app.prototype.logarPorToken = function () {
        var _this = this;
        var mutation = "\n      logarPorToken {\n        ok\n        mensagem\n        nome\n        token\n        tipo\n        idUsuario\n      }\n    ";
        this.mensagemAlerta.mensagem = 'Verificando Login...';
        this.mensagemAlerta.exibir = true;
        this.status = StatusFormType.processando;
        this.clsApolloClient.mutation(this, mutation, 'logarPorToken', this.mensagemAlerta).then(function (rs) {
            _this.mensagemAlerta.mensagem = rs.mensagem;
            if (rs.ok) {
                store.dispatch("logar", rs).then(function () {
                    // LoginStoreModule.logar( rs )
                    localStorage.setItem(AUTH_TOKEN, rs.token);
                    StatusStoreModule.refreshStatus(_this);
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    if (EMDESENVOLVIMENTO && PAGINA_INICIAL_EMDESENVOLVIMENTO.length > 0) {
                        _this.$router.push(PAGINA_INICIAL_EMDESENVOLVIMENTO);
                    }
                    else {
                        // TODO - Página Inicial - Ver se por token já cai em vagas....
                        _this.$router.push('/');
                    }
                });
            }
            else {
                _this.status = StatusFormType.inicial;
                _this.mensagemAlerta.exibir = false;
            }
        }).catch(function (err) {
            console.log('ERRO....', err);
        });
    };
    app.prototype.logar = function () {
        var _this = this;
        var clsUtils = new ClsUtils();
        // @ts-ignore
        if (this.$refs.form.validate()) {
            var logar = "\n        logar (login: " + clsUtils.ConverterEmGql(this.registro) + ") {\n            ok\n            mensagem\n            nome\n            tipo\n            idUsuario\n            token\n          }\n      ";
            this.mensagemAlerta.mensagem = 'Verificando Usuário e Senha...';
            this.mensagemAlerta.exibir = true;
            this.status = StatusFormType.processando;
            this.clsApolloClient.mutation(this, logar, 'logar', this.mensagemAlerta).then(function (rs) {
                _this.mensagemAlerta.mensagem = rs.mensagem;
                if (rs.ok) {
                    store.dispatch("logar", rs).then(function () {
                        //LoginStoreModule.logar( rs )
                        localStorage.setItem(AUTH_TOKEN, rs.token);
                        _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                        _this.$router.push('/');
                    });
                }
                else {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                console.log('ERRO....', err);
            });
        }
    };
    app = __decorate([
        Component({
            components: {
                Alerta: Alerta
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
