import { __assign, __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import Alerta from '../../components/alert.vue';
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsUtils from 'zlib-utils/ClsUtils';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { StatusStoreModule } from '@/store/modules/StatusStore';
// @ts-ignore
import { mask } from 'vue-the-mask';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Dados Pessoais',
            mensagem: 'Obtendo dados Pessoais. Aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.registro = {
            nome: '',
            cep: '',
            endereco: '',
            bairro: '',
            cidade: '',
            uf: '',
            fone: '',
            foneOutros: ''
        };
        _this.tmpRegistro = __assign({}, _this.registro);
        return _this;
    }
    Object.defineProperty(app.prototype, "StatusFormType", {
        get: function () { return StatusFormType; },
        enumerable: false,
        configurable: true
    });
    app.prototype.mounted = function () {
        this.getDados();
    };
    app.prototype.getDados = function () {
        var _this = this;
        var query = "\n    getDadosPessoais {\n      nome\n      cep\n      endereco\n      bairro\n      cidade\n      uf\n      fone\n      foneOutros\n    }    \n    ";
        this.mensagemAlerta.exibir = true;
        var clsApolloClient = new ClsApolloClient();
        clsApolloClient.query(this, query, 'getDadosPessoais', this.mensagemAlerta).then(function (rs) {
            if (rs) {
                _this.registro = __assign({}, rs);
                _this.tmpRegistro = __assign({}, _this.registro);
            }
            _this.mensagemAlerta.exibir = false;
            if (EMDESENVOLVIMENTO && (!rs || !rs.nome)) {
                _this.registro = {
                    nome: 'Marcelo João Zanatta',
                    cep: '35500010',
                    endereco: 'R. Sacramento, 23',
                    bairro: 'Bom Pastor',
                    cidade: 'Divinopolis',
                    uf: 'MG',
                    fone: '37988118785',
                    foneOutros: ''
                };
            }
        });
    };
    app.prototype.gravar = function () {
        var _this = this;
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.mensagemAlerta.mensagem = 'Gravando Dados Pessoais...';
            this.mensagemAlerta.exibir = true;
            this.status = StatusFormType.processando;
            this.confirmarCadastro().then(function (rs) {
                StatusStoreModule.refreshStatus(_this);
                _this.mensagemAlerta.mensagem = rs.mensagem;
                if (rs.ok) {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.status = StatusFormType.inicial;
                    _this.tmpRegistro = __assign({}, _this.registro);
                }
                else {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                console.log('ERRO....', err);
            });
        }
    };
    app.prototype.confirmarCadastro = function () {
        var clsUtils = new ClsUtils();
        var mutation = "\n      setDadosPessoais(dados: " + clsUtils.ConverterEmGql(this.registro) + ") {\n        ok\n        mensagem\n      }\n    ";
        var clsApolloClient = new ClsApolloClient();
        return clsApolloClient.mutation(this, mutation, 'setDadosPessoais', this.mensagemAlerta);
    };
    app = __decorate([
        Component({
            components: {
                Alerta: Alerta
            },
            directives: {
                mask: mask
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
