import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Alerta from '../../components/alert.vue';
import { StatusFormType } from '@/utils/dataTypes';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsApolloClient from '../../utils/ClsApolloClient';
import store from '@/store';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Logout',
            mensagem: 'Saindo da Aplicação...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.status = StatusFormType.inicial;
        return _this;
    }
    app.prototype.mounted = function () {
        this.logout();
    };
    Object.defineProperty(app.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    app.prototype.logout = function () {
        var _this = this;
        var clsApolloClient = new ClsApolloClient();
        var logout = "\n        logout {\n          ok\n          mensagem\n        }\n      ";
        this.mensagemAlerta.mensagem = 'Saindo da Aplicação...';
        this.mensagemAlerta.exibir = true;
        this.status = StatusFormType.processando;
        clsApolloClient.mutation(this, logout, 'logout', this.mensagemAlerta).then(function (rs) {
            _this.mensagemAlerta.mensagem = rs.mensagem;
            if (rs.ok) {
                store.dispatch("logout").then(function () {
                    //LoginStoreModule.logout()
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.$router.push('/Login');
                });
            }
            else {
                _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                _this.status = StatusFormType.inicial;
            }
        }).catch(function (err) {
            console.log('ERRO....', err);
        });
    };
    app = __decorate([
        Component({
            components: {
                Alerta: Alerta
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
