import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import { mask } from 'vue-the-mask';
// @ts-ignore
import { VMoney } from 'v-money';
import { moneyMask } from './questionarioPerguntaCls';
import { validadoresFrontEnd } from 'zlib-utils';
import { PerguntaType } from '@/interfaces/backend/RHInterfaces';
var Resposta = /** @class */ (function (_super) {
    __extends(Resposta, _super);
    function Resposta() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.validadoresFrontEnd = validadoresFrontEnd;
        // @ts-ignore
        _this.resposta = _this.pergunta.configuracao.tipo == PerguntaType.MultiplaEscolha ? [] : '';
        return _this;
    }
    Object.defineProperty(Resposta.prototype, "classePadrao", {
        get: function () {
            return 'px-1 mx-2 py-0 my-0';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Resposta.prototype, "PerguntaType", {
        get: function () {
            return PerguntaType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Resposta.prototype, "mascaraMoney", {
        get: function () {
            return moneyMask;
        },
        enumerable: false,
        configurable: true
    });
    Resposta.prototype.onChange = function () {
        this.$emit('input', { idQuestionario: this.pergunta.idQuestionario, resposta: this.resposta });
    };
    __decorate([
        Prop()
    ], Resposta.prototype, "pergunta", void 0);
    __decorate([
        Watch('resposta')
    ], Resposta.prototype, "onChange", null);
    Resposta = __decorate([
        Component({
            directives: {
                mask: mask,
                money: VMoney
            }
        })
    ], Resposta);
    return Resposta;
}(Vue));
export default Resposta;
