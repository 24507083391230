import { __assign, __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { clsUtils, validadoresFrontEnd } from 'zlib-utils';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import { StatusFormType } from '@/utils/dataTypes';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
// import { StatusStoreModule } from '@/store/modules/StatusStore'
import { VueEditor } from "vue2-editor";
import Alerta from '../../components/alert.vue';
// @ts-ignore
import { mask } from 'vue-the-mask';
import { TIPOVAGADESCRICAO, TipoVagaRole } from '@/interfaces/backend/UsuarioInterfaces';
import { CUSTOMTOOLBAR } from '@/config/CustomToolBar';
import { PerguntaType } from '@/interfaces/backend/RHInterfaces';
import { ISO_TO_USUARIO_DATA, USUARIO_DATA_TO_ISO } from '../../utils/FormatarDados';
import autoCompleteCad from '../../components/autoCompleteCad.vue';
import inputChip from '../../components/inputChip.vue';
import vagasQuestionario from './VagasRHQuestionario.vue';
import _ from 'lodash';
import { DateTime } from 'luxon';
var curriculumDetalhe = /** @class */ (function (_super) {
    __extends(curriculumDetalhe, _super);
    function curriculumDetalhe() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Vaga',
            mensagem: '',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.search = '';
        _this.TIPOVAGADESCRICAO = TIPOVAGADESCRICAO;
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.formValid = false;
        _this.dialog = false;
        _this.editedItem = _this.resetModel();
        _this.status = StatusFormType.inicial;
        _this.clsApollo = new ClsApolloClient();
        _this.cabecalhos = [
            {
                text: 'Empresa',
                align: 'left',
                sortable: true,
                value: 'empresa.nome'
            },
            {
                text: 'Titulo',
                align: 'left',
                sortable: true,
                value: 'titulo'
            },
            {
                text: 'Candidatos',
                align: 'right',
                sortable: true,
                value: 'candidatos'
            },
            {
                text: 'Desistentes',
                align: 'right',
                sortable: true,
                value: 'desistentes'
            },
            {
                text: 'Ativa',
                align: 'left',
                sortable: true,
                value: "ativa"
            },
            {
                text: 'Limite',
                align: 'left',
                sortable: true,
                value: 'dataLimite'
            },
            { text: 'Status', value: 'status', sortable: false },
            { text: 'Ações', value: 'actions', sortable: false }
        ];
        _this.registros = [];
        _this.CUSTOMTOOLBAR = CUSTOMTOOLBAR;
        // Perguntas
        _this.pergunta = {
            configuracao: {
                opcoes: [],
                tamanho: 0,
                tipo: PerguntaType.SimNao
            },
            pergunta: ''
        };
        // Editar Data Limite
        _this.frmDataValid = false;
        _this.dialogEditarDataLimite = false;
        return _this;
    }
    curriculumDetalhe.prototype.ISO_TO_USUARIO_DATA = function (qual) {
        return ISO_TO_USUARIO_DATA(qual);
    };
    Object.defineProperty(curriculumDetalhe.prototype, "StatusFormType", {
        get: function () {
            return StatusFormType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(curriculumDetalhe.prototype, "tituloDialog", {
        get: function () {
            return this.status == StatusFormType.incluindo ? 'Incluir' : this.status == StatusFormType.editando ? "Editar" : "Excluir";
        },
        enumerable: false,
        configurable: true
    });
    curriculumDetalhe.prototype.mounted = function () {
        this.getDetalhe();
    };
    curriculumDetalhe.prototype.resetModel = function () {
        return {
            idVaga: 0,
            idUsuario: 0,
            descritivo: '',
            ativa: false,
            dataLimite: '',
            horario: '',
            remuneracao: '',
            tipo: TipoVagaRole.CLT,
            titulo: '',
            idEmpresa: 0,
            beneficios: [],
            questionario: []
        };
    };
    curriculumDetalhe.prototype.btCancelar = function () {
        this.dialog = false;
        this.status = StatusFormType.inicial;
    };
    curriculumDetalhe.prototype.btIncluir = function () {
        this.status = StatusFormType.incluindo;
        if (EMDESENVOLVIMENTO) {
            this.editedItem = {
                idVaga: 0,
                idUsuario: 0,
                descritivo: 'Descritivo da Vaga',
                ativa: false,
                dataLimite: '30/01/2021',
                horario: 'Das 08 as 18',
                remuneracao: 'A combinar',
                tipo: TipoVagaRole.CLT,
                titulo: 'Vaga Teste Aplicativo',
                idEmpresa: 1,
                beneficios: ["VT", "VA", "Plano Saúde"],
                questionario: []
            };
        }
        else {
            this.editedItem = this.resetModel();
        }
    };
    curriculumDetalhe.prototype.btConfirmar = function () {
        var _this = this;
        if (this.status == StatusFormType.editando || this.status == StatusFormType.incluindo) {
            var dadosParaSalvar = __assign({}, this.editedItem);
            dadosParaSalvar.dataLimite = USUARIO_DATA_TO_ISO(dadosParaSalvar.dataLimite);
            dadosParaSalvar.questionario = _.map(dadosParaSalvar.questionario, function (oque) {
                return { pergunta: oque.pergunta, configuracao: JSON.stringify(oque.configuracao) };
            });
            // @ts-ignore
            if (this.$refs.form.validate()) {
                var mutation = "\n          setVaga(vaga: " + clsUtils.ConverterEmGql(dadosParaSalvar) + " ) {\n            ok\n            mensagem\n          }\n        ";
                this.clsApollo.mutation(this, mutation, 'setVaga', this.mensagemAlerta, 'Atualizando Vaga...').then(function (rs) {
                    if (rs.ok) {
                        _this.dialog = false;
                        _this.status = StatusFormType.inicial;
                        _this.getDetalhe();
                    }
                });
            }
        }
        else {
            var mutation = "\n        delVaga(idVaga: " + this.editedItem.idVaga + ") {\n          ok\n          mensagem\n        }\n      ";
            this.clsApollo.mutation(this, mutation, 'delVaga', this.mensagemAlerta, 'Excluindo Vaga').then(function (rs) {
                if (rs.ok) {
                    _this.dialog = false;
                    _this.status = StatusFormType.inicial;
                    _this.getDetalhe();
                }
            });
        }
    };
    curriculumDetalhe.prototype.editItem = function (item) {
        var _this = this;
        this.getDados(item.idVaga).then(function (rs) {
            if (rs) {
                _this.status = StatusFormType.editando;
            }
        });
    };
    curriculumDetalhe.prototype.deleteItem = function (item) {
        var _this = this;
        this.getDados(item.idVaga).then(function (rs) {
            if (rs) {
                _this.status = StatusFormType.excluindo;
            }
        });
    };
    curriculumDetalhe.prototype.getDados = function (idVaga) {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Vaga';
        var query = "\n      getVaga (idVaga: " + idVaga + ") {\n        idVaga\n        idUsuario\n        descritivo\n        ativa\n        dataLimite\n        horario\n        remuneracao\n        tipo\n        titulo\n        idEmpresa\n        beneficios {\n          descricao\n        }\n        questionario {\n          pergunta\n          configuracao\n        }\n      }\n    ";
        return this.clsApollo.query(this, query, 'getVaga', this.mensagemAlerta).then(function (rs) {
            _this.editedItem = Object.assign({}, rs);
            _this.editedItem.beneficios = _.map(rs.beneficios, 'descricao');
            _this.editedItem.questionario = _.map(rs.questionario, function (oque) {
                return { pergunta: oque.pergunta, configuracao: JSON.parse(oque.configuracao) };
            });
            _this.editedItem.dataLimite = ISO_TO_USUARIO_DATA(_this.editedItem.dataLimite);
            _this.dialog = true;
            // StatusStoreModule.refreshStatus( this )
            return rs;
        });
    };
    curriculumDetalhe.prototype.getDetalhe = function () {
        var _this = this;
        this.mensagemAlerta.mensagem = 'Pequisando Vaga';
        var query = "\n      getVagas (search: \"" + this.search + "\", idEmpresa: 0) {\n        idVaga\n        titulo\n        idEmpresa\n        ativa\n        dataLimite\n        candidatos\n        desistentes\n        empresa {\n          nome\n        }\n      }\n    ";
        this.clsApollo.query(this, query, 'getVagas', this.mensagemAlerta).then(function (rs) {
            _this.registros = rs;
            _this.mensagemAlerta.exibir = false;
        });
    };
    Object.defineProperty(curriculumDetalhe.prototype, "classePadrao", {
        get: function () {
            return 'py-1 my-0';
        },
        enumerable: false,
        configurable: true
    });
    curriculumDetalhe.prototype.alterarStatusVaga = function (item) {
        var _this = this;
        var mutation = "\n      updateVagaAtiva (idVaga: " + item.idVaga + ", ativa: " + !item.ativa + ") {\n        ok\n        mensagem\n      }\n    ";
        this.clsApollo.mutation(this, mutation, 'updateVagaAtiva', this.mensagemAlerta, 'Atualizando Vaga...').then(function (rs) {
            if (rs.ok) {
                _this.dialog = false;
                _this.getDetalhe();
            }
        });
    };
    // Auxiliar para imprimir status da vaga para usuário
    curriculumDetalhe.prototype.vagaExpirada = function (data) {
        return data < DateTime.local().toISODate();
    };
    curriculumDetalhe.prototype.editarDataLimite = function (item) {
        this.dialogEditarDataLimite = true;
        this.editedItem = __assign({}, item);
        this.editedItem.dataLimite = ISO_TO_USUARIO_DATA(this.editedItem.dataLimite);
    };
    curriculumDetalhe.prototype.btConfirmarDataLimite = function () {
        var _this = this;
        //@ts-ignore
        if (this.$refs.frmDataLimite.validate()) {
            var mutation = "\n      updateDataLimite (idVaga: " + this.editedItem.idVaga + ", dataLimite: \"" + USUARIO_DATA_TO_ISO(this.editedItem.dataLimite) + "\") {\n        ok\n        mensagem\n      }\n      ";
            this.clsApollo.mutation(this, mutation, 'updateDataLimite', this.mensagemAlerta, 'Atualizando Data Limite...').then(function (rs) {
                if (rs.ok) {
                    _this.getDetalhe();
                    _this.dialogEditarDataLimite = false;
                }
            });
        }
    };
    curriculumDetalhe.prototype.btCancelarDataLimite = function () {
        this.dialogEditarDataLimite = false;
    };
    curriculumDetalhe = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                VueEditor: VueEditor,
                autoCompleteCad: autoCompleteCad,
                inputChip: inputChip,
                vagasQuestionario: vagasQuestionario
            },
            directives: {
                mask: mask
            }
        })
    ], curriculumDetalhe);
    return curriculumDetalhe;
}(Vue));
export default curriculumDetalhe;
