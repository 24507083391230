import Vue from 'vue';
import VueRouter from 'vue-router';
import ContatosDigitais from '../views/curriculum/ContatosDigitais.vue';
import Cursos from '../views/curriculum/Cursos.vue';
import DadosPessoais from '../views/curriculum/DadosPessoais.vue';
import Download from '../views/curriculum/Download.vue';
import Experiencias from '../views/curriculum/Experiencias.vue';
import Formacao from '../views/curriculum/Formacao.vue';
import Habilidades from '../views/curriculum/Habilidades.vue';
import Login from '../views/login/Login.vue';
import Logout from '../views/login/Logout.vue';
import MenuInicial from '../layout/MenuInicial.vue';
import NovoUsuario from '../views/usuarios/NovoUsuario.vue';
import AlterarSenha from '../views/usuarios/AlterarSenha.vue';
import ReenviarSenha from '../views/usuarios/ReenviarSenha.vue';
import Perfil from '../views/curriculum/Perfil.vue';
import Selfie from '../views/curriculum/Selfie.vue';
import StepByStep from '../views/curriculum/StepByStep.vue';
import Vagas from '../views/vagas/Vagas.vue';
import VagasCandidatar from '../views/vagas/VagasCandidatar.vue';
import Empresas from '../views/RH/Empresas.vue';
import VagasRH from '../views/RH/VagasRH.vue';
import ListarCandidatos from '../views/RH/ListarCandidatos.vue';
import Teste from '../testes_apagar/Teste.vue';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { LoginStoreModule } from '@/store/modules/LoginStore';
Vue.use(VueRouter);
var rotasLivresLogin = ['Login', 'Testes', 'Vagas', 'ReenviarSenha', 'NovoUsuario', '/'];
var routesTeste = EMDESENVOLVIMENTO ? [
    {
        path: '/Testes',
        name: 'Testes',
        component: Teste
    }
] : [];
var routesGeral = [
    {
        path: '/Vagas',
        name: 'Vagas',
        component: Vagas
    },
    {
        path: '/AlterarSenha',
        name: 'AlterarSenha',
        component: AlterarSenha
    },
    {
        path: '/ReenviarSenha',
        name: 'ReenviarSenha',
        component: ReenviarSenha
    },
    {
        path: '/VagasCandidatar/:idVaga',
        name: 'VagasCandidatar',
        component: VagasCandidatar
    },
];
var routesRH = [
    {
        path: '/Empresas',
        name: 'Empresas',
        component: Empresas
    },
    {
        path: '/ListarCandidatos/:rsVaga',
        name: 'ListarCandidatos',
        component: ListarCandidatos
    },
    {
        path: '/VagasRH',
        name: 'VagasRH',
        component: VagasRH
    }
];
var routesCandidatos = [
    {
        path: '/ContatosDigitais',
        name: 'ContatosDigitais',
        component: ContatosDigitais
    },
    {
        path: '/Cursos',
        name: 'Cursos',
        component: Cursos
    },
    {
        path: '/DadosPessoais',
        name: 'DadosPessoais',
        component: DadosPessoais
    },
    {
        path: '/Download',
        name: 'Download',
        component: Download
    },
    {
        path: '/Experiencias',
        name: 'Experiencias',
        component: Experiencias
    },
    {
        path: '/Formacao',
        name: 'Formacao',
        component: Formacao
    },
    {
        path: '/Habilidades',
        name: 'Habilidades',
        component: Habilidades
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login
    },
    {
        path: '/Logout',
        name: 'Logout',
        component: Logout
    },
    {
        path: '/',
        name: 'MenuInicial',
        component: MenuInicial
    },
    {
        path: '/NovoUsuario',
        name: 'NovoUsuario',
        component: NovoUsuario
    },
    {
        path: '/Perfil',
        name: 'Perfil',
        component: Perfil
    },
    {
        path: '/Selfie',
        name: 'Selfie',
        component: Selfie
    },
    {
        path: '/StepByStep',
        name: 'StepByStep',
        component: StepByStep
    }
    /*,
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ // '../views/About.vue')
    // }
];
var router = new VueRouter({
    routes: routesCandidatos.concat(routesRH).concat(routesGeral).concat(routesTeste)
});
// let contador:number = 0
router.beforeResolve(function (to, from, next) {
    /*
    contador++
  
    console.log( "=================================" )
    console.log( 'CONTADOR: ', contador )
  
    if ( !LoginStoreModule.logado )
      console.log( 'Dentro do Before Resolve....', 'LoginStoreModule.logado', LoginStoreModule.logado, 'to', to, 'from', from )
  
    console.log( "Dentro de Before Resolver" )
  
    */
    if (rotasLivresLogin.includes(to.name ? to.name : '') || LoginStoreModule.logado || to.name == "Login") {
        // console.log( "Next()" )
        next(); //proceed to the route
    }
    else {
        // console.log( "Entrei dentro do else...." )
        next({ replace: true, path: '/Login' });
    }
});
export default router;
