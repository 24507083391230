import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import Alerta from '../../components/alert.vue';
import pdf from 'vue-pdf';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pdf = '';
        _this.mensagemAlerta = {
            titulo: 'Download',
            mensagem: 'Downloading, aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        return _this;
    }
    app.prototype.mounted = function () {
        this.btDownload(1);
    };
    app.prototype.btDownload = function (idModelo) {
        var _this = this;
        var query = "\n      download(modelo: " + idModelo + ") {\n        ok\n        mensagem\n        arquivo\n      }\n    ";
        this.mensagemAlerta.exibir = true;
        var clsApolloClient = new ClsApolloClient();
        clsApolloClient.query(this, query, 'download', this.mensagemAlerta).then(function (rs) {
            if (rs) {
                _this.pdf = 'data:application/pdf;base64,'.concat(rs.arquivo);
                // this.pdf = 'data:application/pdf;'.concat( rs.arquivo )
                //console.log(rs.arquivo)
                //this.pdf = rs.arquivo
            }
            _this.mensagemAlerta.exibir = false;
        });
    };
    app = __decorate([
        Component({
            components: {
                Alerta: Alerta,
                pdf: pdf
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
