export var TipoUsuarioRole;
(function (TipoUsuarioRole) {
    TipoUsuarioRole[TipoUsuarioRole["USUARIO"] = 0] = "USUARIO";
    TipoUsuarioRole[TipoUsuarioRole["RH"] = 1] = "RH";
    TipoUsuarioRole[TipoUsuarioRole["ADMIN"] = 2] = "ADMIN";
    TipoUsuarioRole[TipoUsuarioRole["ALUNO"] = 3] = "ALUNO";
})(TipoUsuarioRole || (TipoUsuarioRole = {}));
export var TipoVagaRole;
(function (TipoVagaRole) {
    TipoVagaRole[TipoVagaRole["CLT"] = 0] = "CLT";
    TipoVagaRole[TipoVagaRole["ESTAGIO"] = 1] = "ESTAGIO";
    TipoVagaRole[TipoVagaRole["FREELANCER"] = 2] = "FREELANCER";
    TipoVagaRole[TipoVagaRole["PJ"] = 3] = "PJ";
    TipoVagaRole[TipoVagaRole["TEMPORARIO"] = 4] = "TEMPORARIO";
})(TipoVagaRole || (TipoVagaRole = {}));
export var TIPOVAGADESCRICAO = [
    { tipo: TipoVagaRole.CLT, descricao: 'CLT' },
    { tipo: TipoVagaRole.ESTAGIO, descricao: 'Estágio' },
    { tipo: TipoVagaRole.FREELANCER, descricao: 'Free Lancer' },
    { tipo: TipoVagaRole.PJ, descricao: 'Pessoa Jurírica' },
    { tipo: TipoVagaRole.TEMPORARIO, descricao: 'Temporário' }
];
