import { __assign, __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import Alerta from '../../components/alert.vue';
import { validadoresFrontEnd } from 'zlib-utils';
import { StatusFormType } from '@/utils/dataTypes';
import ClsApolloClient from '@/utils/ClsApolloClient';
import { TipoAlertaMensagemType } from '@/interfaces/FormInterface';
import ClsUtils from 'zlib-utils/ClsUtils';
import { EMDESENVOLVIMENTO } from '@/config/backend/emDesenvolvimento';
import { StatusStoreModule } from '@/store/modules/StatusStore';
// @ts-ignore
import { mask } from 'vue-the-mask';
var app = /** @class */ (function (_super) {
    __extends(app, _super);
    function app() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.mensagemAlerta = {
            titulo: 'Contatos Digitais',
            mensagem: 'Obtendo Contatos Digitais. Aguarde...',
            tipo: TipoAlertaMensagemType.aguarde,
            exibir: false
        };
        _this.validadoresFrontEnd = validadoresFrontEnd;
        _this.status = StatusFormType.inicial;
        _this.formValid = false;
        _this.registro = {
            whatsapp: '',
            email: '',
            twitter: '',
            facebook: '',
            instagram: '',
            linkedin: ''
        };
        _this.tmpRegistro = __assign({}, _this.registro);
        return _this;
    }
    Object.defineProperty(app.prototype, "StatusFormType", {
        get: function () { return StatusFormType; },
        enumerable: false,
        configurable: true
    });
    app.prototype.mounted = function () {
        this.getDados();
    };
    app.prototype.getDados = function () {
        var _this = this;
        var query = "\n    getContatoDigital {\n      whatsapp\n      email\n      twitter\n      facebook\n      instagram\n      linkedin      \n    }    \n    ";
        this.mensagemAlerta.exibir = true;
        var clsApolloClient = new ClsApolloClient();
        clsApolloClient.query(this, query, 'getContatoDigital', this.mensagemAlerta).then(function (rs) {
            if (rs) {
                _this.registro = rs;
                _this.tmpRegistro = __assign({}, _this.registro);
            }
            _this.mensagemAlerta.exibir = false;
            if (EMDESENVOLVIMENTO && (!rs || !rs.whatsapp)) {
                _this.registro = {
                    whatsapp: '37988118785',
                    email: 'zanatta@fleekcursos.com.br',
                    twitter: '@twitterzanatta',
                    facebook: 'zanattapeople facebook',
                    instagram: 'zanatta instagram',
                    linkedin: 'zanatta linkedin'
                };
            }
        });
    };
    app.prototype.gravar = function () {
        var _this = this;
        // @ts-ignore
        if (this.$refs.form.validate()) {
            this.mensagemAlerta.mensagem = 'Gravando Contatos Digitais...';
            this.mensagemAlerta.exibir = true;
            this.status = StatusFormType.processando;
            this.confirmarCadastro().then(function (rs) {
                _this.mensagemAlerta.mensagem = rs.mensagem;
                StatusStoreModule.refreshStatus(_this);
                if (rs.ok) {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.aviso;
                    _this.mensagemAlerta.mensagem = rs.mensagem;
                    _this.status = StatusFormType.inicial;
                    _this.tmpRegistro = __assign({}, _this.registro);
                }
                else {
                    _this.mensagemAlerta.tipo = TipoAlertaMensagemType.erro;
                    _this.mensagemAlerta.mensagem = rs.mensagem;
                    _this.status = StatusFormType.inicial;
                }
            }).catch(function (err) {
                console.log('ERRO....', err);
            });
        }
    };
    app.prototype.confirmarCadastro = function () {
        var clsUtils = new ClsUtils();
        var mutation = "\n      setContatoDigital(dados: " + clsUtils.ConverterEmGql(this.registro) + ") {\n        ok\n        mensagem\n      }\n    ";
        var clsApolloClient = new ClsApolloClient();
        return clsApolloClient.mutation(this, mutation, 'setContatoDigital', this.mensagemAlerta);
    };
    app = __decorate([
        Component({
            components: {
                Alerta: Alerta
            },
            directives: {
                mask: mask
            }
        })
    ], app);
    return app;
}(Vue));
export default app;
