import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import { LoginStoreModule } from './store/modules/LoginStore';
import { MENU } from './config/Menu';
import { EMDESENVOLVIMENTO, VERSAO_SISTEMA } from './config/backend/emDesenvolvimento';
import Notificacao from './layout/notificacao/Notificacao.vue';
import { TipoUsuarioRole } from './interfaces/backend/UsuarioInterfaces';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.drawer = false;
        _this.items = MENU;
        return _this;
    }
    Object.defineProperty(App.prototype, "nomeUsuario", {
        get: function () {
            return LoginStoreModule.nome.concat(' | ').concat(VERSAO_SISTEMA);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "tipoUsuario", {
        get: function () {
            return LoginStoreModule.tipo;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "token", {
        get: function () {
            return EMDESENVOLVIMENTO ? LoginStoreModule.token : '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "logado", {
        get: function () {
            return LoginStoreModule.logado;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "tipoUsuarioLogado", {
        get: function () {
            return LoginStoreModule.logado && LoginStoreModule.tipo == TipoUsuarioRole.USUARIO;
        },
        enumerable: false,
        configurable: true
    });
    App.prototype.btClickMenu = function (destino) {
        if (destino && this.$router.currentRoute.path != destino) {
            this.$router.push(destino);
        }
        this.drawer = false;
    };
    App = __decorate([
        Component({
            components: {
                Notificacao: Notificacao
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
