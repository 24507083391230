import { __decorate, __extends } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import curriculumDetalhe from '../../components/curriculumDetalhe.vue';
var Formacao = /** @class */ (function (_super) {
    __extends(Formacao, _super);
    function Formacao() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.SetupDetalhe = {
            titulo: 'Formação',
            metodoGraphQL: 'Formacao',
            objetoGraphQL: 'formacao',
            campoChave: 'idFormacao',
            mensagemSemRegistro: 'Cadastre sua Formação Acadêmica',
            titulosCampos: {
                instituicao: 'Instituição',
                periodo: 'Período',
                descricao: 'Tipo',
                resumo: 'Descrição'
            }
        };
        return _this;
    }
    Formacao = __decorate([
        Component({
            components: {
                curriculumDetalhe: curriculumDetalhe
            }
        })
    ], Formacao);
    return Formacao;
}(Vue));
export default Formacao;
